*{
	&
	&:before
	&:after{
		box-sizing border-box
	}
}

html, body{
	height 100%
}

body{
	background #fff
	font-size 16px
	font-family 'Playfair Display'
	color #fff
	padding 35px 35px 0
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1{
	font-size 24px
}

h2{
	font-size 20px
}

h3{
	font-size 18px
}

a{
	color #000
	text-decoration none
	transition color .3s
	&:hover{
		color #aaa
		text-decoration none
	}
	&.underlined{
		span{
			position relative
			color #fff
			// text-decoration underline
			&:after{
				position-absolute(auto, 0, 0px, 0)
				display inline-block
				content ''
				height 1px
				border-top 1px solid #fff
			}
		}
	}
}

.logo{
	// width 265px
	width 80%
	height 83px
	background url('../img/logo.png') no-repeat
	background-size contain
	background-position 50%
	text-indent -9999px
	display block
	margin auto
}

// ::-webkit-scrollbar {
//     width: 0px;
// }
// ::-webkit-scrollbar-track {
//     background-color: #fff;
//     border-left: none;
// }
// ::-webkit-scrollbar-thumb {
//     background-color: #fff;
// }
// ::-webkit-scrollbar-thumb:hover {
//     background-color: #fff;
// }