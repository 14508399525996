.frameBorder{
	display none
}

.frameBorder-right{
	@extend .frameBorder
}

.frameBorder-left{
	// @extend .frameBorder
	position-absolute(0, 0, auto, 0)
	position fixed
	z-index 200
	background #fff
	height 20px
}

.latLongCtn{
	display none
}

main{
	padding-bottom 65px
}

section{
	width 100%
	height calc((100vh - 100px) * 1.5)
	overflow hidden
	position relative
}

.connectorSection{
	display none
}

.home, .contact{
	background #000
}

.home{
	height calc(100vh - 85px)
}

.mapCtn{
	display none
}

.navButtonCtn{
	width 100%
	height 100%
	position relative
	z-index 2
	background #fff
	.navButton{
		width 65px
		height 65px
		margin 0 auto
		i{
			width 100%
			height 100%
			display block
			color #000
			text-align center
			line-height 65px
		}
	}
}

footer{
	position-absolute(auto, 20px, 0, 20px)
	position fixed
	height 65px
	background #fff
	z-index 200
	> a.noticeLink{
		display none
	}
	nav{
		overflow hidden
		z-index 1
		background #fff
		position-absolute(auto, 0, -100vh, 0)
		transition .3s bottom
		&.open{
			// top auto
			bottom 65px
		}
		ul{
			list-style-type none
			padding 0
			margin 0
			li{
				display block
				margin 0
				a{
					text-align center
					font-size 18px
					color #000
					text-transform uppercase
					line-height 65px
					display block
					
					&[href="#globality"]{
						display none
					}
				}
				&.active {
					a{
						color #aaa
					}
				}
			}
		}
	}
}