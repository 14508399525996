/** 
 * iPad with portrait orientation.
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait){
  // .foo {
  //   height: 1024px;
  // }
  	// frame
	section{
		height 1386px
	}
	
	.home{
		height 924px
	}
	
	nav{
		bottom -1024px
	}
  	
  	// bgpics
	.tilesCtnInner{
		width 659px
		height 616px
	}

	.tile {
		width 219px
		height 219px
	}
	
	// home
	.centeredLogo{
		margin-bottom 154px
	}
	
	.claim01{
		margin-bottom 51px
	}
}

/** 
 * iPad with landscape orientation.
 */
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape){
  // .foo {
  //   height: 768px;
  // }
  	// frame
	section{
		height 1002px
	}
	
	.home{
		height 668px
	}
	
	nav{
		bottom -768px
	}
  	
  	// bgpics
	.tilesCtnInner{
		width 494px
		height 451px
	}

	.tile {
		width 165px
		height 165px
	}
	
	// home
	.centeredLogo{
		margin-bottom 115px
	}
	
	.claim01{
		margin-bottom 38px
	}
}

/**
 * iPhone 5
 * You can also target devices with aspect ratio.
 */
@media screen and (device-aspect-ratio: 40/71) {
  // .foo {
  //   height: 500px;
  // }
}