// .homeContent{
// 	width 100%
// 	height 100%
// 	position-absolute(0,0,0,0)
// }

.scrollTxt{
	display none
}

.centeredLogo{
	@extend .logo
	margin auto
	absolute(top,right,left,bottom)
	width 80%
	height 83px
}

.logo{
	height 40px
}

.claim{
		background-repeat no-repeat
		background-position 50%
		background-size contain
		// width 80%
		height 13px
}

.claim01{
	@extend .claim
	background-image url('../img/claim.png')
	left 25%
	margin 51vh 0 2vh 0
}

.claim02{
	@extend .claim
	background-image url('../img/claim-2.png')
	left 75%
}