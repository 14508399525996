body.siteNotice{
	position relative
	main{
		overflow auto
		background #000
		padding 20px
		position-absolute(20px, 20px, 65px, 20px)
		.view{
			height auto
			a{
				color #ddd
				text-decoration underline
			}
		}
		
	}
}

body{
	padding: 20px;
}