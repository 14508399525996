.backgrounds{
	background-repeat no-repeat
	background-size auto 100%
	background-position 50%
	width 100%
	height 100%
	
	&.parallaxItem{
		width 100%
		height 100%
	}
}

.bg_pic01{
	@extend .backgrounds	
	background-image url('../img/bg-1-m.jpg')
}

.bg_pic02{
	@extend .backgrounds	
	background-image url('../img/bg-2-m.jpg')
}

.bg_pic03{
	@extend .backgrounds	
	background-image url('../img/bg-3-m.jpg')
}

.bg_pic04{
	@extend .backgrounds	
	background-image url('../img/bg-4-m.jpg')
}


.tilesCtn{
	background-color #ccc
}

.tilesCtnInner{
	width calc((150vh / 7) * 3)
	height calc(((150vh - 100px) / 7) * 3)
	position relative
}

.tile {
	background-repeat no-repeat
	background-size cover
	float left
	width calc(150vh / 7)
	height calc(150vh / 7)
}

.tile01{
	background-image url('../img/tile-01.jpg')
}

.tile02{
	background-image url('../img/tile-02.jpg')
}

.tile03{
	background-image url('../img/tile-03.jpg')
}

.tile04{
	background-image url('../img/tile-04.jpg')
}

.tile05{
	background-image url('../img/tile-05.jpg')
}

.tile06{
	background-image url('../img/tile-06.jpg')
}

.tile07{
	background-image url('../img/tile-07.jpg')
}

.tile08{
	background-image url('../img/tile-08.jpg')
}

.tile09{
	background-image url('../img/tile-09.jpg')
}

.tile10{
	background-image url('../img/tile-10.jpg')
}

.tile11{
	background-image url('../img/tile-11.jpg')
}

.tile12{
	background-image url('../img/tile-12.jpg')
}

.tile13{
	background-image url('../img/tile-13.jpg')
}

.tile14{
	background-image url('../img/tile-14.jpg')
}

.tile15{
	background-image url('../img/tile-15.jpg')
}

.tile16{
	background-image url('../img/tile-01.jpg')
}

.tile17{
	background-image url('../img/tile-02.jpg')
}

.tile18{
	background-image url('../img/tile-03.jpg')
}

.tile19{
	background-image url('../img/tile-04.jpg')
}

.tile20{
	background-image url('../img/tile-05.jpg')
}

.tile21{
	background-image url('../img/tile-06.jpg')
}

.tile22{
	background-image url('../img/tile-07.jpg')
}

.tile23{
	background-image url('../img/tile-08.jpg')
}

.tile24{
	background-image url('../img/tile-09.jpg')
}

.tile25{
	background-image url('../img/tile-10.jpg')
}

.tile.tileLogo{
	background-color #000 !important
	background-image none
	padding 30px
}

.individualistLogo{
	background-image url('../img/individualist.png')
	background-size contain
	background-repeat no-repeat
	background-position 50%
	width 100%
	height 100%
}