.comic
	background #000 url("../img/bg-5-m.jpg") no-repeat
	background-position 50% 40%
	background-size 210%
	.quote
		color #FFF
		text-align center
		position absolute
		margin 30% auto
		left 0
		right 0
		top 50%