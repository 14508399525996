.contact{
	color #fff
	text-align center
	height auto
	padding-bottom 35px
	.logo{
		margin-top 30px
	}
	
	.formSuccess, .formFailure{
		display none
	}
	
	.formSuccess{
		font-size 24px
	}
	
	.formFailure{
		margin-bottom 20px
		padding 0 55px
	}
	form{
		color #000
		width 80%
		margin 0 auto
		input, textarea{
			text-align left
			display block
			width 100%
			margin-bottom 15px
			padding 10px 15px
			background #fff
			border none
		}
		input[type=submit]{
			display inline-block
			text-align center
			margin 0
			width auto
			padding 10px 25px
		}
	}
}

.contact-info{
	width 80%
	margin 0 auto 35px auto
	font-size 16px !important
}
